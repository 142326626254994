import React from 'react';
import {
  Container,
  Typography,
  Box,
  Link,
} from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Zásady ochrany osobních údajů
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Poslední aktualizace: 9. února 2025
        </Typography>

        <Typography variant="body1" paragraph>
          Tyto zásady ochrany osobních údajů popisují, jak jsou vaše osobní údaje shromažďovány a používány při návštěvě davidrelich.com.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Informace, které shromažďujeme
        </Typography>

        <Typography variant="body1" paragraph>
          Když navštívíte web, automaticky shromažďujeme určité informace o vašem zařízení, včetně informací o vašem webovém prohlížeči, IP adrese a některých souborech cookie.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Kontaktní formulář a reCAPTCHA
        </Typography>

        <Typography variant="body1" paragraph>
          Náš web používá Google reCAPTCHA k ochraně kontaktního formuláře před spamem. Když používáte reCAPTCHA, Google shromažďuje hardwarové a softwarové informace, jako jsou data o zařízení a aplikaci, a odesílá je Googlu k analýze. Informace shromážděné v souvislosti s vaším používáním služby budou použity pro zlepšení reCAPTCHA a pro obecné účely bezpečnosti. Nebudou použity pro personalizovanou reklamu společností Google.
        </Typography>

        <Typography variant="body1" paragraph>
          Pro více informací o Google reCAPTCHA a zásadách ochrany osobních údajů Google navštivte:{' '}
          <Link href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
            Zásady ochrany osobních údajů Google
          </Link>
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Soubory cookie
        </Typography>

        <Typography variant="body1" paragraph>
          Používáme cookies k zapamatování vašich preferencí souhlasu s cookies a k umožnění funkce Google reCAPTCHA. Můžete se rozhodnout cookies přijmout nebo odmítnout. Většina webových prohlížečů cookies automaticky přijímá, ale obvykle můžete upravit nastavení svého prohlížeče tak, aby cookies odmítal.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Vaše práva
        </Typography>

        <Typography variant="body1" paragraph>
          Pokud jste rezidentem EU, máte právo na přístup k osobním údajům, které o vás uchováváme, a požádat o jejich opravu, aktualizaci nebo vymazání. Pokud byste chtěli toto právo uplatnit, kontaktujte nás prosím.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Změny
        </Typography>

        <Typography variant="body1" paragraph>
          Tyto zásady ochrany osobních údajů můžeme čas od času aktualizovat, abychom zohlednili změny v našich postupech nebo z jiných provozních, právních či regulačních důvodů.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Kontaktujte nás
        </Typography>

        <Typography variant="body1" paragraph>
          Pro více informací o našich postupech ochrany osobních údajů, pokud máte otázky nebo pokud chcete podat stížnost, kontaktujte nás prosím pomocí kontaktního formuláře na našem webu.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
