import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Link,
  Paper,
  Typography,
} from '@mui/material';

const CookieConsent = ({ onAccept }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setOpen(true);
    } else if (consent === 'accepted') {
      onAccept();
    }
  }, [onAccept]);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setOpen(false);
    onAccept();
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setOpen(false);
  };

  if (!open) return null;

  return (
    <Paper
      elevation={3}
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        p: 2,
        backgroundColor: 'rgba(32, 32, 32, 0.95)',
        color: 'white',
      }}
    >
      <Box sx={{
        maxWidth: 1200,
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: 2,
      }}>
        <Typography variant="body1" sx={{ flex: 1, minWidth: '280px' }}>
          Tento web používá cookies a službu Google reCAPTCHA pro zlepšení uživatelského zážitku a ochranu proti spamu. 
          Používáním reCAPTCHA souhlasíte s{' '}
          <Link 
            href="https://policies.google.com/privacy" 
            target="_blank" 
            rel="noopener noreferrer"
            color="inherit"
            underline="always"
          >
            Zásadami ochrany osobních údajů
          </Link>
          {' '}a{' '}
          <Link 
            href="https://policies.google.com/terms" 
            target="_blank" 
            rel="noopener noreferrer"
            color="inherit"
            underline="always"
          >
            Smluvními podmínkami
          </Link>
          {' '}společnosti Google.
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleAccept}
            sx={{ 
              minWidth: '100px',
              fontWeight: 'bold',
            }}
          >
            Přijmout
          </Button>
          <Button 
            variant="outlined" 
            color="inherit" 
            onClick={handleDecline}
            sx={{ 
              minWidth: '100px',
              borderColor: 'white',
              '&:hover': {
                borderColor: 'white',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            Odmítnout
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default CookieConsent;
