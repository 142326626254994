import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import * as emailjs from '@emailjs/browser';

// EmailJS credentials
const SERVICE_ID = 'service_xr56rf4';
const TEMPLATE_ID = 'template_91td33p';
const PUBLIC_KEY = 'hbLs-4I4ufXT6dN9Z';
const RECAPTCHA_SITE_KEY = '6LdV59EqAAAAAEllrBrVa5WpNKhaLJAXK9SC4k8f';

function Contact({ cookiesAccepted }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);

  useEffect(() => {
    // Load reCAPTCHA script only if cookies are accepted
    if (cookiesAccepted) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
      script.async = true;
      script.defer = true;
      
      script.onload = () => {
        window.grecaptcha.ready(() => {
          console.log('reCAPTCHA is ready');
          setIsRecaptchaLoaded(true);
        });
      };

      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
        setIsRecaptchaLoaded(false);
      };
    }
  }, [cookiesAccepted]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({
      ...prev,
      open: false
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!cookiesAccepted) {
      setSnackbar({
        open: true,
        message: 'Pro odeslání formuláře prosím přijměte cookies.',
        severity: 'error'
      });
      return;
    }

    if (!isRecaptchaLoaded) {
      setSnackbar({
        open: true,
        message: 'Prosím počkejte na načtení všech komponent formuláře.',
        severity: 'error'
      });
      return;
    }

    try {
      setIsSubmitting(true);
      console.log('Starting form submission...');

      // Execute reCAPTCHA v3
      console.log('Checking reCAPTCHA score...');
      const token = await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { 
        action: 'submit_contact' 
      });

      // Verify the token with our PHP endpoint
      const verifyResponse = await fetch('/verify-recaptcha.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token })
      });

      if (!verifyResponse.ok) {
        throw new Error('Failed to verify reCAPTCHA');
      }

      const verifyResult = await verifyResponse.json();
      
      if (!verifyResult.success) {
        throw new Error('Suspicious activity detected');
      }

      // Initialize EmailJS
      emailjs.init(PUBLIC_KEY);

      // Prepare email parameters (without reCAPTCHA token)
      const templateParams = {
        from_name: formData.name,
        reply_to: formData.email,
        message: formData.message
      };

      console.log('Sending email...');
      const result = await emailjs.send(
        SERVICE_ID,
        TEMPLATE_ID,
        templateParams,
        PUBLIC_KEY
      );
      console.log('EmailJS Response:', result);

      setSnackbar({
        open: true,
        message: 'Zpráva byla úspěšně odeslána!',
        severity: 'success'
      });

      // Reset form
      setFormData({
        name: '',
        email: '',
        message: ''
      });
    } catch (error) {
      console.error('Form submission error:', error);
      let errorMessage = 'Došlo k chybě při odesílání zprávy. Prosím zkuste to znovu později.';
      
      if (error.message === 'Suspicious activity detected') {
        errorMessage = 'Váš požadavek byl vyhodnocen jako podezřelý. Prosím zkuste to znovu později.';
      }

      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      id="kontakt"
      sx={{
        bgcolor: 'grey.50',
        py: 8,
        position: 'relative'
      }}
    >
      <Container maxWidth="lg">
        <Typography
          component="h2"
          variant="h3"
          align="center"
          color="text.primary"
          gutterBottom
          sx={{
            fontWeight: 700,
            mb: 4
          }}
        >
          Kontaktujte mě...
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Paper 
              elevation={0} 
              sx={{ 
                p: 4, 
                bgcolor: 'background.paper',
                borderRadius: 2
              }}
            >
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="Jméno"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      sx={{
                        '& .MuiInputLabel-root': {
                          fontFamily: "'Inter', sans-serif"
                        },
                        '& .MuiInputBase-root': {
                          fontFamily: "'Inter', sans-serif"
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      sx={{
                        '& .MuiInputLabel-root': {
                          fontFamily: "'Inter', sans-serif"
                        },
                        '& .MuiInputBase-root': {
                          fontFamily: "'Inter', sans-serif"
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Zpráva"
                      name="message"
                      multiline
                      rows={4}
                      value={formData.message}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      sx={{
                        '& .MuiInputLabel-root': {
                          fontFamily: "'Inter', sans-serif"
                        },
                        '& .MuiInputBase-root': {
                          fontFamily: "'Inter', sans-serif"
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'flex-end',
                      mt: 2 
                    }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={!cookiesAccepted || isSubmitting}
                        endIcon={isSubmitting ? <CircularProgress size={20} /> : <SendIcon />}
                        sx={{ 
                          fontFamily: "'Inter', sans-serif"
                        }}
                      >
                        {isSubmitting ? 'Odesílání...' : 'Odeslat Zprávu'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>

        {!cookiesAccepted && (
          <Box 
            sx={{ 
              mt: 4, 
              textAlign: 'center',
              p: 3,
              bgcolor: 'background.paper',
              borderRadius: 2,
              maxWidth: 'md',
              mx: 'auto'
            }}
          >
            <Typography 
              variant="body1" 
              color="text.secondary"
              gutterBottom
              sx={{ 
                fontFamily: "'Inter', sans-serif",
                mb: 2
              }}
            >
              Vzhledem k tomu, že jste se rozhodli nepovolit cookies, kontaktní formulář není k dispozici.
              Můžete mě kontaktovat přímo na emailové adrese:
            </Typography>
            <Typography
              variant="h6"
              component="p"
              sx={{
                fontFamily: "'Inter', sans-serif",
                fontWeight: 500,
                color: 'primary.main'
              }}
            >
              david@davidrelich.cz
            </Typography>
          </Box>
        )}

        {/* Footer */}
        <Box
          sx={{
            py: 3,
            px: 2,
            mt: 8,
            backgroundColor: 'transparent',
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
          >
            {new Date().getFullYear()} David Relich. Všechna práva vyhrazena.
          </Typography>
        </Box>
      </Container>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{
            fontFamily: "'Inter', sans-serif"
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Contact;
