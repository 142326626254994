import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, useMediaQuery, Box } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Projects from './components/Projects';
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import CookieConsent from './components/CookieConsent';
import CookieSettings from './components/CookieSettings';
import './styles/global.css';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [cookiesAccepted, setCookiesAccepted] = useState(
    localStorage.getItem('cookieConsent') === 'accepted'
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: '#1a4b8c',
            light: '#446ca8',
            dark: '#0d2d5c',
          },
          background: {
            default: prefersDarkMode ? '#0f1319' : '#ffffff',
            paper: prefersDarkMode ? '#161b24' : '#ffffff',
          },
          text: {
            primary: prefersDarkMode ? '#ffffff' : '#000000',
            secondary: prefersDarkMode ? '#b0b0b0' : '#666666',
          },
          grey: {
            50: prefersDarkMode ? '#1a2027' : '#fafafa'
          }
        },
        typography: {
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
          ].join(','),
          body1: {
            fontSize: '1.1rem',
          },
          h2: {
            fontSize: '3.75rem',
            '@media (max-width:600px)': {
              fontSize: '2.5rem',
            },
          },
          h3: {
            fontSize: '3rem',
            '@media (max-width:600px)': {
              fontSize: '2rem',
            },
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                borderRadius: 8,
              },
            },
          },
          MuiTextField: {
            styleOverrides: {
              root: {
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8,
                },
              },
            },
          },
        },
      }),
    [prefersDarkMode],
  );

  const handleCookieConsent = () => {
    setCookiesAccepted(true);
  };

  const handleCookieRevoke = () => {
    setCookiesAccepted(false);
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <Projects />
              <Contact cookiesAccepted={cookiesAccepted} />
            </>
          } />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Box 
          sx={{ 
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1000
          }}
        >
          <CookieSettings onRevoke={handleCookieRevoke} onAccept={handleCookieConsent} />
        </Box>
        <CookieConsent onAccept={handleCookieConsent} />
      </ThemeProvider>
    </Router>
  );
}

export default App;
