import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from '@mui/material';
import CookieIcon from '@mui/icons-material/Cookie';

const CookieSettings = ({ onRevoke, onAccept }) => {
  const [open, setOpen] = React.useState(false);
  const cookieConsent = localStorage.getItem('cookieConsent');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRevoke = () => {
    localStorage.removeItem('cookieConsent');
    onRevoke();
    handleClose();
    // Force reload to show the cookie consent banner again
    window.location.reload();
  };

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    onAccept();
    handleClose();
    window.location.reload();
  };

  return (
    <>
      <Button
        startIcon={<CookieIcon />}
        onClick={handleOpen}
        color="inherit"
        size="small"
        sx={{ 
          textTransform: 'none',
          opacity: 0.7,
          '&:hover': {
            opacity: 1
          }
        }}
      >
        Nastavení cookies
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="cookie-settings-dialog"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="cookie-settings-dialog">
          Nastavení cookies
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" paragraph>
              Na tomto webu používáme cookies pro:
            </Typography>
            <Typography variant="body1" component="div" sx={{ ml: 2 }}>
              • Zapamatování vašich preferencí ohledně cookies<br />
              • Ochranu kontaktního formuláře pomocí Google reCAPTCHA
            </Typography>
          </Box>
          <Typography variant="body1" paragraph>
            Aktuální stav: {cookieConsent === 'accepted' ? 
              'Cookies jsou povoleny' : 
              'Cookies jsou zakázány'}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Poznámka: Změna nastavení cookies způsobí obnovení stránky.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Zavřít
          </Button>
          {cookieConsent === 'accepted' ? (
            <Button onClick={handleRevoke} color="error" variant="outlined">
              Zrušit souhlas
            </Button>
          ) : (
            <Button onClick={handleAccept} color="primary" variant="contained">
              Povolit cookies
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CookieSettings;
