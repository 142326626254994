import React from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material';

function Hero() {
  const theme = useTheme();

  return (
    <Box
      id="hero"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        position: 'relative',
        bgcolor: theme.palette.background.default,
      }}
    >
      {/* Left side - Background Image */}
      <Box
        sx={{
          position: 'absolute',
          width: '40%',
          height: '100%',
          left: 0,
          overflow: 'hidden',
          display: { xs: 'none', md: 'block' },
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            zIndex: 1,
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url('/hero-background.jpg')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            zIndex: 0,
            animation: 'zoomInOut 20s ease-in-out infinite alternate',
          },
          '@keyframes zoomInOut': {
            '0%': {
              transform: 'scale(1)',
            },
            '100%': {
              transform: 'scale(1.1)',
            },
          },
        }}
      />

      {/* Right side - Content */}
      <Box
        sx={{
          width: { xs: '100%', md: '60%' },
          marginLeft: { xs: 0, md: '40%' },
          bgcolor: theme.palette.mode === 'dark' ? 'background.default' : 'background.paper',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Container 
          maxWidth="md" 
          sx={{ 
            py: { xs: 12, md: 8 },
            px: { xs: 2, sm: 4, md: 6 },
          }}
        >
          <Typography
            component="h1"
            variant="h2"
            gutterBottom
            sx={{ 
              fontFamily: "'Playfair Display', serif",
              fontWeight: 700,
              color: theme.palette.text.primary,
              mb: 3,
              fontSize: { xs: '2.5rem', sm: '3.2rem', md: '3.5rem' },
            }}
          >
            David Relich
          </Typography>
          
          <Typography 
            variant="body1" 
            sx={{
              fontFamily: "'Inter', sans-serif",
              mb: 4,
              color: theme.palette.text.secondary,
              fontSize: { xs: '1.3rem', sm: '1.5rem' },
            }}
          >
            Full-stack vývojář webových aplikací
          </Typography>

          <Typography 
            variant="body1"
            sx={{
              fontFamily: "'Inter', sans-serif",
              mb: 2.5,
              lineHeight: 1.8,
              color: theme.palette.text.secondary,
            }}
          >
            Jsem full-stack vývojář webových aplikací s více než 20 lety zkušeností v oblasti webových služeb a technické podpory. V minulosti jsem pracoval jako vývojář webových aplikací pro americkou společnost Etison LLC, kde jsem se zaměřoval na vývoj, testování a podporu aplikací, včetně tvorby technické dokumentace a poskytování podpory oddělení zákaznických služeb.
          </Typography>

          <Typography 
            variant="body1"
            sx={{
              fontFamily: "'Inter', sans-serif",
              mb: 2.5,
              lineHeight: 1.8,
              color: theme.palette.text.secondary,
            }}
          >
            Již od roku 2018 funguji jako OSVČ. Spravuji weby, řeším technické problémy, poskytuji web hostingové služby a programuji doplňky. Více než 95 % mých klientů jsou anglicky hovořící zákazníci z Irska, Velké Británie, a USA.
          </Typography>

          <Typography 
            variant="body1"
            sx={{
              fontFamily: "'Inter', sans-serif",
              mb: 2.5,
              lineHeight: 1.8,
              color: theme.palette.text.secondary,
            }}
          >
            Mám také dlouholetou zkušenost se správou internetových prezentací a IT podporou pro irskou cestovní kancelář Justsplit.com, kde se již více než osmnáct let starám o webové stránky, podnikové sítě a poskytuji technickou podporu zaměstnanců.
          </Typography>

          <Typography 
            variant="body1"
            sx={{
              fontFamily: "'Inter', sans-serif",
              mb: 4,
              lineHeight: 1.8,
              color: theme.palette.text.secondary,
            }}
          >
            Během celé své kariéry jsem pracoval v anglickém jazyce, což mi umožňuje efektivně komunikovat a spolupracovat v mezinárodním prostředí.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}

export default Hero;
