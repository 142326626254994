import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Button,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

const projects = [
  {
    title: 'Rezervační systém pro zábavní parky',
    description: 'Online rezervační systém pro správu a rezervaci herních a sportovních ploch. Systém umožňuje správu více sportovišť, arén, časových slotů a typů aktivit. Primárně byl vytvořen pro rezervace nedalekého zábavního parku, kde provozují laser game, laser sniper a virtual game arény.',
    image: '/arena-booking.jpg',
    technologies: ['Laravel', 'Filament', 'Tailwind CSS', 'Alpine.js', 'Livewire', 'MySQL'],
  },
  {
    title: 'Fakturační aplikace pro freelancery',
    description: 'Webová aplikace pro správu faktur a plateb. Umožňuje vystavování faktur, sledování plateb a generování finančních přehledů. Nabízí rovněž možnost předplatného, ze kterého se pak podle nastaveného časového intervalu opakovaně automaticky generují faktury.',
    image: '/billing-app.jpg',
    technologies: ['Laravel', 'Filament', 'Tailwind CSS', 'Alpine.js', 'Livewire', 'MySQL'],
  },
];

function Projects() {
  return (
    <Box
      id="projekty"
      sx={{
        py: { xs: 8, md: 12 },
        bgcolor: 'grey.50',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          component="h2"
          variant="h3"
          align="center"
          gutterBottom
          sx={{
            mb: 6,
            fontFamily: "'Playfair Display', serif",
            fontWeight: 700,
          }}
        >
          Projekty
        </Typography>

        <Grid container spacing={4}>
          {projects.map((project, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: 8,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="280"
                  image={project.image}
                  alt={project.title}
                  sx={{
                    objectFit: 'cover',
                  }}
                />
                <CardContent sx={{ flexGrow: 1, p: 3 }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h3"
                    sx={{
                      fontFamily: "'Inter', sans-serif",
                      fontWeight: 600,
                      mb: 2,
                    }}
                  >
                    {project.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{
                      mb: 3,
                      fontFamily: "'Inter', sans-serif",
                      lineHeight: 1.7,
                    }}
                  >
                    {project.description}
                  </Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                    {project.technologies.map((tech, techIndex) => (
                      <Chip
                        key={techIndex}
                        label={tech}
                        size="small"
                        sx={(theme) => ({
                          bgcolor: theme.palette.mode === 'dark' 
                            ? 'rgba(68, 108, 168, 0.15)'
                            : 'rgba(26, 75, 140, 0.08)',
                          color: theme.palette.mode === 'dark'
                            ? '#8bb4f7'
                            : theme.palette.primary.main,
                          fontFamily: "'Inter', sans-serif",
                          fontWeight: 500,
                          fontSize: '0.75rem',
                          height: '24px',
                          '&:hover': {
                            bgcolor: theme.palette.mode === 'dark'
                              ? 'rgba(68, 108, 168, 0.25)'
                              : 'rgba(26, 75, 140, 0.12)',
                          },
                        })}
                      />
                    ))}
                  </Stack>
                  <Button 
                    size="small" 
                    endIcon={<LaunchIcon />}
                    sx={{ 
                      opacity: 0.7,
                      fontFamily: "'Inter', sans-serif",
                      mt: 2
                    }}
                  >
                    Již brzy
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Projects;
