import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';

const pages = ['Projekty', 'Kontakt'];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolled]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId.toLowerCase().replace(/[ěščřžýáíéů]/g, ''));
    if (element) {
      const offset = 80;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    handleCloseNavMenu();
  };

  return (
    <AppBar 
      position="fixed" 
      elevation={scrolled ? 2 : 0}
      sx={{
        background: scrolled
          ? theme.palette.mode === 'dark'
            ? 'rgba(18, 18, 18, 0.9)'
            : 'rgba(255, 255, 255, 0.9)'
          : 'transparent',
        backdropFilter: scrolled ? 'blur(10px)' : 'none',
        transition: 'all 0.3s ease-in-out',
        boxShadow: scrolled
          ? theme.palette.mode === 'dark'
            ? '0 4px 6px rgba(0, 0, 0, 0.3)'
            : '0 4px 6px rgba(0, 0, 0, 0.1)'
          : 'none',
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ minHeight: { xs: '70px', md: '80px' } }}>
          {/* Name/Brand for desktop */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              display: { xs: 'none', md: 'flex' },
              fontFamily: "'Playfair Display', serif",
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: scrolled
                ? theme.palette.text.primary
                : theme.palette.mode === 'dark'
                ? '#fff'
                : '#000',
              textDecoration: 'none',
              opacity: scrolled ? 1 : 0,
              transition: 'all 0.3s ease-in-out',
            }}
          >
            David Relich
          </Typography>

          {/* Mobile menu */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ 
                color: scrolled
                  ? theme.palette.text.primary
                  : theme.palette.mode === 'dark'
                  ? '#fff'
                  : '#000',
                transition: 'color 0.3s ease-in-out'
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => scrollToSection(page)}>
                  <Typography textAlign="center" sx={{ fontFamily: "'Inter', sans-serif" }}>
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Name/Brand for mobile */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: "'Playfair Display', serif",
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: scrolled
                ? theme.palette.text.primary
                : theme.palette.mode === 'dark'
                ? '#fff'
                : '#000',
              textDecoration: 'none',
              justifyContent: 'center',
              opacity: scrolled ? 1 : 0,
              transition: 'all 0.3s ease-in-out'
            }}
          >
            David Relich
          </Typography>

          {/* Desktop menu */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => scrollToSection(page)}
                sx={{ 
                  mx: 1.5,
                  color: scrolled
                    ? theme.palette.text.primary
                    : theme.palette.mode === 'dark'
                    ? '#fff'
                    : '#000',
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '0.95rem',
                  fontWeight: 500,
                  position: 'relative',
                  transition: 'all 0.3s ease-in-out',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    width: '0%',
                    height: '2px',
                    bottom: '4px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: theme.palette.primary.main,
                    transition: 'width 0.3s ease-in-out',
                  },
                  '&:hover': {
                    backgroundColor: 'transparent',
                    '&::after': {
                      width: '80%',
                    },
                  },
                }}
              >
                {page}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
